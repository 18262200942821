/**
 *	common.js
 */
'use strict';
// import $ from 'jquery'; // webpackから読み込む時

/* CONFIG
--------------------------------------------------*/
var APP = APP || {};  // グローバル変数格納用
APP.spBreakPoint = 767;
APP.tbBreakPoint = 1023;

APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
APP.cww = APP.ww;  // リサイズ前のウインドウ幅を保持
APP.cwh = APP.wh;  // リサイズ前のウインドウ高を保持
APP.scrollTop = $(window).scrollTop();
APP.scrollBottom = APP.scrollTop + APP.wh;
APP.isSP = (APP.ww <= APP.spBreakPoint) ? true : false;

/* USERAGENT
--------------------------------------------------*/
import { _ua } from './component/ua';
if (_ua.Tablet) {
  $('body').addClass('tablet');
} else if (_ua.Mobile) {
  $('body').addClass('mobile');
  APP.isSP = true;
} else {
  $('body').addClass('is-pc');
}
if (_ua.iOS) {
  $('body').addClass('ios');
}
if (_ua.userAgent.indexOf('msie') != -1 || _ua.userAgent.indexOf('trident') != -1) {
  $('body').addClass('ie');
} else if(_ua.userAgent.indexOf('edge') != -1) {
  $('body').addClass('edge');
}

/* LOADED
--------------------------------------------------*/
$(window).on('load', function () {
  $('body').addClass('is-loaded');
  $('.loading').fadeOut(function() {
    $(this).remove();
  });
});

/* READY
--------------------------------------------------*/
$(function() {
  // # で始まるリンクにスムーススクロール
  APP.scSpeed = 400;
  function scrollHdl(_href) {
    var target = $(_href == "#" || _href == "" ? 'html' : _href);
    var position = target.offset().top;
    if (!APP.isSP) {
      position -= 120;
    } else {
      position -= 60;
      navClose();
    }
    $('body,html').animate({scrollTop:position}, APP.scSpeed, 'swing');
  }
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    var href= $(this).attr("href");
    if(href=="#") return false;
    if(href=="#pagetop"){
     $("html, body").animate({scrollTop: 0}, APP.scSpeed, 'swing');
     return false;
    }
    scrollHdl(href);
    return false;
  });
  // 遷移後のページ内リンク
  APP.URLhash = location.hash;
  setTimeout(function() {
    if (APP.URLhash) scrollHdl(APP.URLhash);
  }, 300);

  $('.js-btn-nav').on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('is-open')) {
      navClose();
    } else {
      $(this).addClass('is-open');
      $('body').addClass('is-block');
      $('.js-nav').fadeOut();
      $('.js-nav-main').addClass('is-open');
    }
    return false;
  });
  function navClose() {
    $('.js-btn-nav').removeClass('is-open');
    $('body').removeClass('is-block');
    if(!APP.isSP) $('.js-nav').fadeIn();
    $('.js-nav-main').removeClass('is-open');
  }
  $('.js-nav-main a').on('click', function () {
    navClose();
  })

  $('.select-wrap select[name="件名"]').prepend('<option hidden selected>選択してください</option>');

  $('.js-acc-btn').on('click', function () {
    $(this).toggleClass('is-open');
    $(this).next('.js-acc-content').stop(true, true).slideToggle();
    return false;
  })

  if ($(".js-modal-video").length) {
    $.each($(".js-modal-video"), function (i, value) { 
      var _start = $(this).data('video-start');
      $(this).modalVideo({
        channel: "youtube",
        youtube: {
          autoplay: 1,
          controls: 1, // コントロール表示（0は非表示）
          start: _start
        },
      });
    });
  }

  if (APP.isSP) APP.text_defaultH = '48px';
  else APP.text_defaultH = '90px';
  $('.open-text .btn-open').on('click', function (e) {
    e.preventDefault();
    var _target = $(this).prev('.open-text-content');
    $(this).toggleClass('is-open');
    var _target = $(this).prev('');
    if($(this).hasClass('is-open')) {
      _target.css('height', 'auto');
      var goalH = _target.height();
      _target.css('height', APP.text_defaultH).animate({'height': goalH}, 300);
    }else {
      _target.animate({'height': APP.text_defaultH}, 300);
    }
    return false;
  });

});

/* FUCTION
--------------------------------------------------*/
const observerOptions = {
  root: null,
  rootMargin: '-50px',
  threshold: 0
}
window.addEventListener('DOMContentLoaded', function(e){
  const fadeInUpAll = document.querySelectorAll('.js-anim');
  intersectAction(fadeInUpAll, function (element, isIntersecting) {
    if (isIntersecting) {
      element.classList.add('inview');
    }
  }, observerOptions)
}, {once: true});

// SCROLL
var winScroll = function () {
  // PAGETOP
  // if (!APP.isSP) {
  //   if ((APP.scrollTop + APP.wh) < $('.footer').offset().top) {
  //     $('#pagetop').addClass('is-fixed');
  //   } else {
  //     $('#pagetop').removeClass('is-fixed');
  //   }
  //   if (APP.scrollTop < 10) {
  //     $('#pagetop').fadeOut();
  //   } else {
  //     $('#pagetop').fadeIn();
  //   }
  // }
};

/* RESIZE
--------------------------------------------------*/
APP.resizeFlag = false;
$(window).on('resize orientationchange', function() {
  APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
  APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
  APP.scrollTop = $(window).scrollTop();
  APP.scrollBottom = APP.scrollTop + APP.wh;
  if(!APP.resizeFlag) {
    APP.resizeFlag = true;
    setTimeout(function(){
      if(APP.ww <= APP.spBreakPoint) {
        APP.isSP = true;
        if(APP.cww != APP.ww){
          APP.cww = APP.ww;
          // isSP
        }
      }else {
        APP.isSP = false;
        APP.cww = APP.ww;
        // isPC
      }
      if (_ua.iOS && APP.cwh != APP.wh) {
        if (APP.cwh < APP.wh) {
          $('body').removeClass('is-menu');
        } else {
          $('body').addClass('is-menu');
        }
        APP.cwh = APP.wh;
      }
      APP.resizeFlag = false;
      return APP.resizeFlag;
    },100);
  }
}).trigger('resize');

/* SCROLL
--------------------------------------------------*/
// $(window).on('scroll',function(){
//   APP.scrollTop = $(window).scrollTop();
//   winScroll();
// }).trigger('scroll');